<template>
    <div class="loadMore">
        <vue-data-loading
                :loading="loading"
                :completed="completed"
                :offset="101"
                :listens="['infinite-scroll', 'pull-down']"
                :init-scroll="false"
                @infinite-scroll="infiniteScroll"
                @pull-down="pullDown">
            <div>
                <slot></slot>
            </div>
            <div slot="infinite-scroll-loading"><Icon icon="eos-icons:three-dots-loading" color="#000" width="61px"/></div>
            <div slot="completed">All data has been loaded</div>
        </vue-data-loading>
    </div>
</template>
 
<script>
    import VueDataLoading from 'vue-data-loading'
    import { Icon } from '@iconify/vue2'
    export default {
        name: 'loadMore',
        props:{
            completed:{
                type:Boolean,
                default:false
            },
            loading:{
                type:Boolean,
                default:false
            }
        },
        components: {
            VueDataLoading,
            Icon
        },
        data() {
            return {
                // loading: false,
                page: 1,
                isBack:false
            }
        },
        methods: {
            pullDown() {
                this.page = 1
                this.$emit('changeData',1)
            },
            infiniteScroll() {
                //到底触发的事件
                this.page++;
                this.$emit('changeData',this.page)
            },
        }
    }
</script>